import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer';

const Disclaimer = () => {
    return (
        <Container>
            <Navbar />
            <div className="main-content">
                <h2>Disclaimer</h2>
                <div className="basic-text">
                    The information provided by Bhushondi.com ("we," "us" or "our") on https://www.bhushondi.com (the "Site") is for general informational purposes only. All information on the Site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.
                </div>
                <div className="basic-text">
                    <h3>External Links Disclaimer</h3>
                    The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.
                </div>
                <div className="basic-text">
                    WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                </div>
                <div className="basic-text">
                    <h3>Professional Disclaimer</h3>
                    The Site cannot and does not contain specific advice. The information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. The use of or reliance on any information contained on this site is solely at your own risk.
                </div>
                <div className="basic-text">
                    <h3>Affiliates Disclaimer</h3>
                    The Site may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links. Our affiliates include but are not limited to [List Affiliate Networks].
                </div>
                <div className="basic-text">
                    <h3>Errors and Omissions Disclaimer</h3>
                    While we have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, Bhushondi.com is not responsible for any errors or omissions or for the results obtained from the use of this information. All information in this site is provided "as is," with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability, and fitness for a particular purpose.
                </div>
                <div className="basic-text">
                    <h3>Testimonials Disclaimer</h3>
                    The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences.
                </div>
                <div className="basic-text">
                    YOUR INDIVIDUAL RESULTS MAY VARY.
                </div>
                <div className="basic-text">
                    The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity, where the full testimonial contained extraneous information not relevant to the general public.
                </div>
            </div>

            <Footer />
        </Container>
    )
}

export default Disclaimer

const Container = styled.div`
    .main-content{
        min-height: calc(100vh - 200px);
        width: 100%;

        padding: 50px 100px;

        h2{
            font-size: 1.25rem;
            font-weight: 500;
        }

        .basic-text{
            margin-top: 10px;
            font-size: 0.85rem;
            font-weight: 200;
            line-height: 1.85rem;

            h3{
                font-weight: 500;
                font-size: 0.85rem;
                margin-top: 20px;
            }

            b, strong, a{
                font-weight: 500;
            }

            ul{
                margin-left: 40px;
            }
        }
    }
`