import React, { useState, useEffect } from "react";
import styled from 'styled-components'

// Firebase
import { db } from "../firebase";
import { collection, getDocs, addDoc, updateDoc, doc, query, orderBy, where } from "firebase/firestore";

const ContributionRequests = () => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          // Create a query against the collection.
          const q = query(collection(db, "contributions"), orderBy("timestamp", "desc"));
          const querySnapshot = await getDocs(q);
          setRequests(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
          setError(null);
        } catch (err) {
          setError(err.message);
          console.error("Failed to fetch data:", err);
        }
        setLoading(false);
      };
  
      fetchData();
    }, []);
  
    return (
      <Container>
        <h1>Contribution Requests</h1>
        {error && <p>Error: {error}</p>}
        {loading ? (
          <p>Loading...</p>
        ) : (
          requests.length > 0 ? (
            <div className="all-requests">
              {requests.map((request) => (
                <div className="request" key={request.id}>
                  <strong>Name:</strong> {request.name} <br />
                  <strong>Email:</strong> {request.email} <br />
                  <strong>Content:</strong> {request.content} <br />
                  <strong>Submitted At:</strong> {request.timestamp?.toDate().toLocaleString()}
                </div>
              ))}
            </div>
          ) : (
            <p>No contribution requests found.</p>
          )
        )}
      </Container>
    );
  };
  
  export default ContributionRequests;

  const Container = styled.div`
    padding: 50px;

    h1{
        font-size: 1.5rem;
        font-weight: 600;
    }

    .all-requests{
        .request{
            background-color: #e5cea5;
            padding: 10px;
            margin-top: 20px;

            font-size: 0.85rem;
            font-weight: 300;
            
            strong{
                font-weight: 500;
            }
        }
    }
  `