import React from 'react'
import styled from 'styled-components'
import Navbar from '../Components/Navbar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Footer from '../Components/Footer';

import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import MailIcon from '@material-ui/icons/Mail';
import TelegramIcon from '@material-ui/icons/Telegram';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const SectionContent = () => {
    return (
        <Container>
            <Navbar />
            <div className="artical-opener">
                {/* <div className="evenboxinner">The satirical cartoon, supreme leader of freedom</div> */}
                <div className="rotate">
                    <div class="bubble bubble--highlight">
                        When all is said and done, are the French even allowed to be stupid?
                    </div>
                </div>
                <div className="banner">
                    <img src="https://charliehebdo.fr/wp-content/uploads/2024/04/1656-15-boucq-justeat-5x3-1.png" alt="" />
                    {/* <img src="https://charliehebdo.fr/wp-content/uploads/2020/09/proces39-image.jpg" alt="" /> */}
                </div>
            </div>

            <div className="main-component">
                <Socials>
                    <div className="icon"><TwitterIcon/></div>
                    <div className="icon"><WhatsAppIcon/></div>
                    <div className="icon"><FacebookIcon/></div>
                    <div className="icon"><MailIcon/></div>
                    <div className="icon"><TelegramIcon/></div>
                </Socials>
                <TextComponent>
                    <div className='date'>9 March 2024, Kolkata</div>
                    <p className="heading">Hey French people, listen up. Yeah, that's right, you. Écoutez-moi, motherfuckers.</p>
                    {/* <div className="both-content">
                    <div className="text">Most of the world retains some vestige of the sentimental notion that the French might be smelly, annoying and tedious about cheese but they are, at the very least, much, much smarter than the rest of us. In the English speaking world, being a little bit dumb is one of the defining pillars of who we are. We never read a book and our cinema is cretinous, we think philosophy is gay and upsetting and we elect Donald Trump and Boris Johnson. We’re egregious nincompoops. But we can drool and gibber as much as we like, safe in the notion that all you froggy fuckers are reading Descartes and Spinoza all day long. Or isolating radioactive isotopes while composing villanelles. But perhaps we were wrong all this time. Because it’s beginning to seem like you are just as dumb as we are. Most of the world retains some vestige of the sentimental notion that the French might be smelly, annoying and tedious about cheese but they are, at the very least, much, much smarter than the rest of us. In the English speaking world, being a little bit dumb is one of the defining pillars of who we are. We never read a book and our cinema is cretinous, we think philosophy is gay and upsetting and we elect Donald Trump and Boris Johnson. We’re egregious nincompoops. But we can drool and gibber as much as we like, safe in the notion that all you froggy fuckers are reading Descartes and Spinoza all day long. Or isolating radioactive isotopes while composing villanelles. But perhaps we were wrong all this time. Because it’s beginning to seem like you are just as dumb as we are.</div>
                    <div className="image-content">
                        <img src="https://charliehebdo.fr/wp-content/themes/charlie-hebdo/img/flag-fr-round.svg?x12779" alt="" />
                    </div>
                </div> */}
                    <div class="content">
                        <img className="right-side" src="https://charliehebdo.fr/wp-content/themes/charlie-hebdo/img/flag-fr-round.svg?x12779" alt="Any Image" />
                        <p className='first-para'>Most of the world retains some vestige of the sentimental notion that the French might be smelly, annoying and tedious about cheese but they are, at the very least, much, much smarter than the rest of us. In the English speaking world, being a little bit dumb is one of the defining pillars of who we are. We never read a book and our cinema is cretinous, we think philosophy is gay and upsetting and we elect Donald Trump and Boris Johnson. We’re egregious nincompoops. But we can drool and gibber as much as we like, safe in the notion that all you froggy fuckers are reading Descartes and Spinoza all day long. Or isolating radioactive isotopes while composing villanelles. But perhaps we were wrong all this time. Because it’s beginning to seem like you are just as dumb as we are.</p>
                        <p>Since the beginning of 2021, there have been multiple opinion polls about how willing French people are to accept being vaccinated against Covid-19. The results have been astonishing, unexpected and deeply depressing. Six months ago, a Gallup poll of 144 countries pour L’ONG Britannique Wellcome showed that the French population was more skeptical and distrustful of Covid vaccines than any other developed nation apart from Russia. Countries as disadvantaged as Bangladesh, Rwanda and even the moronic crucible of the US showed more confidence in the utility of vaccination.</p>
                        <img src="https://charliehebdo.fr/wp-content/uploads/2021/06/1506-15-boucq-terrasse.jpg?x12779" alt="" className="left-side" />
                        <p>Percentages of skepticism or vaccine-refusal have varied since but not enough. At best, there is always more than a third of the French population that is consistently unwilling to countenance Covid vaccination. And recent results are scarcely better. The newest poll for Odoxa – Le Figaro – France Info showed that 58% of French respondents did not intend to get vaccinated. The actual facts may end up differing (it’s possible that people are more prepared to bloviate to a pollster than actually risk their own health in the cold light of day). But this is, nonetheless, an extremely concerning statistic. Virologists and epidemiologists do not entirely agree on the minimum required figures but generally conclude that gaining an effective level of herd immunity requires somewhere between 70% and 85% of a population to be vaccinated. If the French continue in their little festival of idiocy, this is all going to be very boring indeed.</p>
                        <p>Stop this bullshit, mes Frenchies. You guys are smart. At least, you were always smart before. We used to laugh at you because of it (because we were the lycée bad boys and being smart is NOT cool). Famously, on the first day of school, an anglophone mother will crouch down at the gates and in a reassuring voice will tell the trepidatious tot ‘Have fun.’ That’s not what a French mother says. The French mother says ‘Behave yourself’, or maybe ‘’Pay attention in class’. This produces very different worlds. And our sloppy, funny, undisciplined one depends on the bedrock foundation of your more rigorous, logical and lettered one. This is not abstract. It’s crucial cultural mechanics.</p>


                        <img src="https://charliehebdo.fr/wp-content/uploads/2021/06/1506-15-boucq-terrasse.jpg?x12779" alt="" className="right-side" />
                        <p>Percentages of skepticism or vaccine-refusal have varied since but not enough. At best, there is always more than a third of the French population that is consistently unwilling to countenance Covid vaccination. And recent results are scarcely better. The newest poll for Odoxa – Le Figaro – France Info showed that 58% of French respondents did not intend to get vaccinated. The actual facts may end up differing (it’s possible that people are more prepared to bloviate to a pollster than actually risk their own health in the cold light of day). But this is, nonetheless, an extremely concerning statistic. Virologists and epidemiologists do not entirely agree on the minimum required figures but generally conclude that gaining an effective level of herd immunity requires somewhere between 70% and 85% of a population to be vaccinated. If the French continue in their little festival of idiocy, this is all going to be very boring indeed.</p>
                        <p>Stop this bullshit, mes Frenchies. You guys are smart. At least, you were always smart before. We used to laugh at you because of it (because we were the lycée bad boys and being smart is NOT cool). Famously, on the first day of school, an anglophone mother will crouch down at the gates and in a reassuring voice will tell the trepidatious tot ‘Have fun.’ That’s not what a French mother says. The French mother says ‘Behave yourself’, or maybe ‘’Pay attention in class’. This produces very different worlds. And our sloppy, funny, undisciplined one depends on the bedrock foundation of your more rigorous, logical and lettered one. This is not abstract. It’s crucial cultural mechanics.</p>
                    </div>
                    <div className="page-links">
                        <a href='/' className="link">Contributed By <b>@Atanu Nayak</b></a>
                        <div className="next-btn">
                            Read Next
                            <ArrowForwardIcon/>
                        </div>
                    </div>
                </TextComponent>
                <RightContent>
                    <h3>Recommended Reads</h3>
                    <div className="more-article"></div>
                    <div className="more-article"></div>
                    <div className="more-article"></div>
                </RightContent>
            </div>



            {/* <Footer/> */}
        </Container>
    )
}

export default SectionContent

const Container = styled.div`
    .artical-opener{
        position: relative;
        margin-top: 30px;

        .banner{
            /* padding: 0 100px; */
            width: calc(100vw - 200px);
            margin: 20px auto;
            height: 550px;
            overflow: hidden;

            display: flex;
            align-items: flex-end;
            border: 5px solid black;

            
            img{
                width: 100%;
            }
            
        }
        
        .evenboxinner{
            transform: rotate(-2deg);
            padding:0 5px;
            background:#ddd;
            border:1px solid #222;
            box-shadow:3px 3px 0 #222;
            font-size: 2rem;
            font-weight: 600;

            position: absolute;
            top: -20px;
            left: 20px;
            z-index: 10;

            max-width: 550px;
        }

        .rotate{
            transform: rotate(-2deg);

            position: absolute;
            bottom: -50px;
            left: 50px;
        }

        .bubble {
            /* cap the width */
            max-width: 700px;
            /* give ample whitespace around and inside of the container */
            margin: 2rem 0;
            padding: 0.2rem 1.25rem;
            text-align: center;
            /* font-family: "Dekko", cursive; */
            /* text-transform: uppercase; */
            font-size: 1.5rem;
            letter-spacing: 0.2rem;
            background: url('data:image/svg+xml;utf8,<svg width="100" height="100" transform="rotate(25)" opacity="0.3" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><g  fill="%23250E17"><circle cx="25" cy="25" r="12.5"/><circle cx="75" cy="75" r="12.5"/><circle cx="75" cy="25" r="12.5"/><circle cx="25" cy="75" r="12.5"/></g></svg>'),
                #fff;
            background-size: 12px, 100%;
            /* solid border */
            border: 0.4rem solid #000;
            /* position relative for the :before pseudo element */
            position: relative;
        }

        /* for the highlight container change the solid backgorund to a yellow-ish hue */
        .bubble--highlight {
            background: url('data:image/svg+xml;utf8,<svg width="100" height="100" transform="rotate(25)" opacity="0.8" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><g  fill="%23d68810"><circle cx="25" cy="25" r="12.5"/><circle cx="75" cy="75" r="12.5"/><circle cx="75" cy="25" r="12.5"/><circle cx="25" cy="75" r="12.5"/></g></svg>'),
                #ffcd28;
            background-size: 13px, 100%;
            font-weight: 700;
        }
        /* for the highlight container always add an exclamation point */
        .bubble--highlight:after {
            /* content: "!"; */
        }
        /* for every .bubble container add a solid background behind the container itself, slightly offset */
        .bubble:before {
            content: "";
            position: absolute;
            left: 0px;
            top: 0.15rem;
            width: 100%;
            height: 100%;
            /* with the same texture, but different color, for both the texture and the background */
            background: url('data:image/svg+xml;utf8,<svg width="100" height="100" transform="rotate(35)" opacity="1" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><g fill="%23250E17"><circle cx="25" cy="25" r="12.5"/><circle cx="75" cy="75" r="12.5"/><circle cx="75" cy="25" r="12.5"/><circle cx="25" cy="75" r="12.5"/></g></svg>'),
                #000;
            background-size: 12px, 100%;
            border: 0.4rem solid #000;
            z-index: -5;
        }

        /* for the tag, add a lot of whitespace below the string, to include the name through the pseudo element */
        .tag {
            margin-bottom: 2rem;
            padding: 1rem 7.5rem 11rem;
            border-radius: 30px;
            font-size: 2rem;
            color: #fff;
            background: #f8012d;
            text-align: center;
            /* font-family: "Lato", sans-serif; */
            box-shadow: 0 1px 15px -7.5px #000000;
            /* position relative for the pseudo element(s) */
            position: relative;
        }
        .tag strong {
            text-transform: uppercase;
            font-size: 3.5rem;
        }
        /* with the :before pseudo element include a solid white background */
        .tag:before {
            content: "";
            position: absolute;
            top: 9rem;
            left: 2%;
            width: 96%;
            height: 9rem;
            background: #fff;
            /* border-radius mathing the .tag container */
            border-radius: 0 0 30px 30px;
        }
        /* with the :after pseudo element add the name of the character */
        .tag:after {
            content: "Peter Parker";
            position: absolute;
            top: 11.25rem;
            left: 0;
            width: 100%;
            text-align: center;
            /* font-family: "Rock Salt", cursive; */
            font-size: 2rem;
            letter-spacing: 0.25rem;
            font-weight: 300;
            font-weight: bold;
            color: #000;
        }
    }

    .main-component{
        display: flex;
        align-items: flex-start;

        padding: 25px 100px;
    }
`


const Socials = styled.div`
    width: 70px;
    /* background-color: black; */
    margin-right: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 10px;
    
    position: sticky;
    top: 0;

    .icon{
        width: 100%;
        aspect-ratio: 1/1;
        margin-bottom: 10px;
        background-color: #f7f7f7;
        border: 1px solid #d8d4d4;
        border-radius: 50%;

        display: grid;
        place-items: center;
    }
`

const TextComponent = styled.div`
    flex: 1;
    /* background-color: green; */

    .date{
        margin-bottom: 10px;
        font-size: 0.85rem;
        font-style: italic;
    }
    
    .heading{
        font-weight: 700;
        font-size: 1.25rem;
        margin-bottom: 40px;
    }


    .left-side {
        float: left;
        padding: 1%;
    }

    .right-side {
        float: right;
        padding: 1%;
    }

    .content, header {
        margin: 0 auto;
    }

    header {
        text-align: center;
    }

    .content img {
        height: 260px;
        margin: 5px 10px;
    }

    .first-para::first-letter {
        font-size: 4rem;
        font-weight: 600;
        line-height: 1rem;
    }

    p{
        font-family: "Newsreader", serif;
        font-size: 1.15rem;
        line-height: 1.75rem;
        margin-bottom: 20px;
    }

    .page-links{
        padding: 20px 0;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .link{
            color: #333;
            margin-right: 15px;
            cursor: pointer;
            font-size: 0.9rem;   
            font-weight: 500;

            text-decoration: none;

            b{
                text-decoration: underline;
            }
        }

        .next-btn{
            padding: 5px 10px;
            background-color: #f7f7f7;
            border: 1px solid #d8d4d4;
            font-size: 0.85rem;   

            display: flex;
            align-items: center;

            svg{
                font-size: 1rem;
                margin-left: 10px;
            }
        }
    }
`

const RightContent = styled.div`
    width: 240px;
    margin-left: 40px;

    display: flex;  
    flex-direction: column;

    .more-article{
        width: 240px;
        height: 240px;
        margin-top: 10px;
        background-color: #f7f7f7;
        border: 1px solid #d8d4d4;
    }
`
