import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import Landing from "./Screens/Landing";
import SectionContent from "./Screens/SectionContent";
import AllArticles from "./Screens/AllArticles";
import Contribute from "./Screens/Contribute";
import ContributionRequests from "./Screens/ContributionRequests";
import Contributors from "./Screens/Contributors";
import Contributor from "./Screens/Contributor";
import Contact from "./Screens/Org/Contact";
import Privacy from "./Screens/Org/Privacy";
import OrgContribute from "./Screens/Org/OrgContribute";
import About from "./Screens/Org/About";
import Disclaimer from "./Screens/Org/Disclaimer";
import ShortSnap from "./Screens/ShortSnap";
import BeeInside from "./Screens/BeeInside";
import BullConversion from "./Screens/BullConversion";
import ShareIcon from '@material-ui/icons/Share';
import CloseIcon from '@material-ui/icons/Close';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';

const App = () => {
  const [openShareBtns, setOpenShareBtns] = useState(false);

  const openShareLinks = () => {
    setOpenShareBtns(!openShareBtns);
  }


  const shareLinkWhatsapp = () => {
    const url = window.location.href;
    const message = `Hey, read this amazing article I came across: ${url}`;

    navigator.clipboard.writeText(message).then(() => {
      console.log('Message copied to clipboard');

      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const shareLinkFacebook = () => {
    const url = window.location.href;
    const message = `Hey, read this amazing article I came across: ${url}`;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(message)}`;
    // const facebookUrl = "https://www.facebook.com/sharer.php?u=https%3A%2F%2Fdev.to%2Fdsasse07%2Fimplementing-a-social-share-feature-jd7";
    window.open(facebookUrl, '_blank');
  };

  const shareLinkGmail = () => {
    const url = window.location.href;
    const subject = 'Check out this amazing article';
    const body = `Hey, read this amazing article I came across: ${url}`;
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(gmailUrl, '_blank');
  };


  return (
    <Container>
      <Routes>
        <Route path="/" element={<Landing />} />

        <Route path="/:CategoryId/articles" element={<AllArticles />} />

        <Route path="/article" element={<SectionContent />} />
        <Route path="/contribute" element={<Contribute />} />

        <Route path="/short-snap/:pageId" element={<ShortSnap />} />
        <Route path="/bee-story" element={<BeeInside />} />
        <Route path="/bull-story" element={<BullConversion />} />

        <Route path="/contribution-requests" element={<ContributionRequests />} />
        <Route path="/contributors" element={<Contributors />} />
        <Route path="/contributor/:contributorId" element={<Contributor />} />

        <Route path="/org/contact" element={<Contact />} />
        <Route path="/org/privacy" element={<Privacy />} />
        <Route path="/org/contribute" element={<OrgContribute />} />
        <Route path="/org/about" element={<About />} />
        <Route path="/org/disclaimer" element={<Disclaimer />} />
      </Routes>

      <Share>
        {
          openShareBtns ? (
            <>
              <div className="btn" onClick={() => shareLinkWhatsapp()}>
                <WhatsAppIcon />
              </div>
              <div className="btn" onClick={() => shareLinkFacebook()}>
                <FacebookIcon />
              </div>
              <div className="btn" onClick={() => shareLinkGmail()}>
                <MailIcon />
              </div>
            </>
          ) : null
        }
        <div className="btn white" onClick={openShareLinks}>
          {
            openShareBtns ? <CloseIcon /> : <ShareIcon/>
          }
        </div>
      </Share>

    </Container>
  )
}

export default App

const Container = styled.div`
  position: relative;
`

const Share = styled.div`
  position: fixed;
  bottom: 30px;
  right: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .btn{
    margin-top: 10px;
    height: 45px;
    aspect-ratio: 1/1;
    border-radius: 100%;
    border: 1px solid #a2a1a1;
    background-color: #75f05d;
    box-shadow: #6e6d6d45 1px 1px 5px 3px;
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg{
      font-size: 1.5rem;
    }
  }

  .white{
    background-color: white;
  }
`