import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer';

const Privacy = () => {
    return (
        <Container>
            <Navbar />
            <div className="main-content">
                <h2>Privacy Policy</h2>
                <div className="basic-text">
                    At Bhushondi.com, we are committed to protecting the privacy and security of our users. This Privacy Policy outlines the types of personal information we collect, how it is used, and the steps we take to ensure that it is protected.
                </div>
                <div className="basic-text">
                    <h3>Information We Collect</h3>
                    We collect information to provide better services to our users. This may include personal information such as your name, email address, phone number, and any other information you provide when you use our website, sign up for an account, or make a purchase.
                </div>
                <div className="basic-text">
                    <h3>How We Use Your Information</h3>
                    Your information helps us personalize and continuously improve your experience at Bhushondi.com. We use your information to communicate with you about our products and services, update you on promotions, and process transactions.
                </div>
                <div className="basic-text">
                    <h3>Sharing of Information</h3>
                    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
                </div>
                <div className="basic-text">
                    <h3>Data Security</h3>
                    We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.
                </div>
                <div className="basic-text">
                    <h3>Your Rights</h3>
                    You have the right to access, correct, or delete your personal information at any time. Please contact us at bhushondi.core@gmail.com to request access to, correct, or delete any personal information that you have provided to us.
                </div>
                <div className="basic-text">
                    <h3>Updates to Our Privacy Policy</h3>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We encourage you to review this Privacy Policy periodically for any changes.
                </div>
                <div className="basic-text">
                    If you have any questions about this Privacy Policy, please contact us at <a href="mailto:bhushondi.core@gmail.com">bhushondi.core@gmail.com</a>.
                </div>
            </div>
            <Footer />
        </Container>
    )
}

export default Privacy

const Container = styled.div`
    .main-content{
        min-height: calc(100vh - 200px);
        width: 100%;

        padding: 50px 100px;

        h2{
            font-size: 1.25rem;
            font-weight: 500;
        }

        .basic-text{
            margin-top: 10px;
            font-size: 0.85rem;
            font-weight: 200;
            line-height: 1.85rem;

            h3{
                font-weight: 500;
                font-size: 0.85rem;
                margin-top: 20px;
            }

            b, a{
                font-weight: 500;
            }
        }
    }
`