import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAE9eH5thb72Y1umMt4B27PXLVEWkTB4QY",
  authDomain: "bhushondi-client.firebaseapp.com",
  projectId: "bhushondi-client",
  storageBucket: "bhushondi-client.appspot.com",
  messagingSenderId: "351716501276",
  appId: "1:351716501276:web:9f98746f3068f6e843fcd9",
  measurementId: "G-CZD87DGGNG"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);