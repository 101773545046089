import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../Components/Navbar'

import beeInside from '../Assets/beeInside.jpg'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const BeeInside = () => {
    return (
        <Container>
            <a href="/" className="back-btn">
                <ArrowBackIosIcon />
                Home Page
            </a>
            <img src={beeInside} alt="" />
        </Container>
    )
}

export default BeeInside

const Container = styled.div`
    width: 100vw;
    padding: 5vh 10vw;

    .back-btn{
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 0.75rem;
        height: 30px; 
        width: 120px; 
        border-radius: 100px;
        background-color: #d6d8df;

        cursor: pointer;
        color: #333;

        svg{
            font-size: 0.85rem;
        }
    }

    img{
        margin-top: 20px;
        width: 100%;
    }
`
