import React from 'react'
import styled from 'styled-components'
import logo2 from '../Assets/logo2.png'
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';

import contactNav from '../Assets/contactNav.png'
import contributeNav from '../Assets/contributeNav.png'
import contributorsNav from '../Assets/contributorsNav.png'

import NavLink1 from '../Assets/Navbar/1.png'
import NavLink2 from '../Assets/Navbar/2.png'
import NavLink3 from '../Assets/Navbar/3.png'
import NavLink4 from '../Assets/Navbar/4.png'
import NavLink5 from '../Assets/Navbar/5.png'
import NavLink6 from '../Assets/Navbar/6.png'

const Navbar = (CategoryId) => {
    console.log(CategoryId.CategoryId);
  return (
    <Container>
        <div className="top">
            <a href="/">
                <img src={logo2} alt="" />
            </a>

            <div className="btns">
                {/* <div className="btn">Subscribe</div> */}
                <a href='/org/contact' className="btn btn-2"><img className='base-size' src={contactNav} alt="" /></a>
                <a href='/contribute' className="btn btn-2"><img className='base-size' src={contributeNav} alt="" /></a>
            </div>
        </div>
        <div className="bottom">
            <a href='/all/articles' className={CategoryId.CategoryId == 'all' ? "btn current-link" : "btn"}>
                <MenuIcon/>
                <img className='base-size' src={NavLink1} alt="" />
            </a>
            <div className="all-links">
                <a href='/trending/articles' className={CategoryId.CategoryId == 'trending' ? "link current-link" : "link"}><img className='base-size' src={NavLink2} alt="" /></a>
                <a href='/nature/articles' className={CategoryId.CategoryId == 'nature' ? "link current-link" : "link"}><img className='base-size' src={NavLink3} alt="" /></a>
                <a href='/drawing/articles' className={CategoryId.CategoryId == 'drawing' ? "link current-link" : "link"}><img className='base-size' src={NavLink4} alt="" /></a>
                <a href='/comic/articles' className={CategoryId.CategoryId == 'comic' ? "link current-link" : "link"}><img className='base-size' src={NavLink5} alt="" /></a>
                <a href='/review/articles' className={CategoryId.CategoryId == 'review' ? "link current-link" : "link"}><img className='base-size' src={NavLink6} alt="" /></a>
            </div>
            <a href='/contributors' className={CategoryId.CategoryId == 'contributors' ? "btn current-link" : "btn"}>
                <PeopleIcon/>
                <img className='base-size small-phone' src={contributorsNav} alt="" />
            </a>
        </div>
    </Container>
  )
}

export default Navbar

const Container = styled.div`
    /* background-color: whitesmoke; */
    width: 100vw;
    padding: 30px 100px;
    padding-bottom: 0;

    .base-size{
        height: 24px;
    }

    .top{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        img{
            height: 80px;
            margin-top: -20px;
        }

        .btns{
            display: flex;
            align-items: center;

            .btn{
                font-size: 0.8rem;
                /* padding: 10px 15px; */
                /* background-color: #e9a001; */
                margin-left: 20px;
                color: white;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 0.1rem;
                text-decoration: none;
                
                .base-size{
                    height: 48px;
                    margin-bottom: -10px;
                }
            }
        }
    }

    .bottom{
        border-top: 1px solid black;
        border-bottom: 1px solid black;

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: 20px;

        text-transform: uppercase;
        font-size: 0.85rem;
        letter-spacing: 0.1rem;
        font-weight: 400;

        .all-links{
            display: flex;
            align-items: center;
            
            display: none;

            .link{
                height: 40px;
                color: #333;
                margin: 0 15px;
                display: grid;
                place-items: center;
                cursor: pointer;
                text-decoration: none;
                border-bottom: 4px solid transparent;

                &:hover{
                    border-bottom: 4px solid orange;
                }
            }

            .current-link{
                border-bottom: 4px solid black;
            }
        }

        .btn{
            height: 40px;
            display: flex;
            align-items: center;
            color: #333;
            text-decoration: none;
            border-bottom: 4px solid transparent;

            svg{
                font-size: 1.25rem;
                margin-right: 10px;
            }

            &:hover{
                border-bottom: 4px solid orange;
            }
        }

        .current-link{
            border-bottom: 4px solid black;
        }
    } 
    
    @media (max-width: 600px) {
        width: 100vw;
        padding: 20px 20px;
        padding-bottom: 0;

        .base-size{
            height: 20px;
        }

        .top{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            img{
                height: 50px;
                margin-top: -10px;
            }

            .btns{
                display: none;

                .btn{
                    font-size: 0.8rem;
                    /* padding: 10px 15px; */
                    /* background-color: #e9a001; */
                    margin-left: 20px;
                    color: white;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    text-decoration: none;
                    
                    .base-size{
                        height: 48px;
                        margin-bottom: -10px;
                    }
                }
            }
        }

        .bottom{
            border-top: 1px solid black;
            border-bottom: 1px solid black;

            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-top: 20px;

            text-transform: uppercase;
            font-size: 0.85rem;
            letter-spacing: 0.1rem;
            font-weight: 400;

            .all-links{
                display: none;

                .current-link{
                    border-bottom: 4px solid black;
                }
            }

            .btn{
                height: 40px;
                display: flex;
                align-items: center;
                color: #333;
                text-decoration: none;
                border-bottom: 4px solid transparent;

                svg{
                    font-size: 1.25rem;
                    margin-right: 10px;
                }

                &:hover{
                    border-bottom: 4px solid orange;
                }
            }

            .current-link{
                border-bottom: 4px solid black;
            }
        } 

        .small-phone{
            height: 15px;
        }
    }
`