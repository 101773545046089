import React from 'react'
import styled from 'styled-components'

const Footer = () => {
  return (
    <Container>
      {/* <a href='/org/contact' className="link">Contact</a> */}
      <a href='/org/privacy' className="link">Privacy</a>
      {/* <a href='/org/contribute' className="link">Contribute</a> */}
      <a href='/org/about' className="link">About</a>
      <a href='/org/disclaimer' className="link">Disclaimer</a>
      
      <div className="below">
        © Bhushandi 2024
      </div>
    </Container>
  )
}

export default Footer

const Container = styled.div`
    width: calc(100vw - 200px);
    margin: auto;

    padding: 50px 100px 80px 100px;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    .link{
        color: #333;
        margin-right: 15px;
        cursor: pointer;
        font-size: 0.85rem;
        font-weight: 500;
    }

    .below{
      position: absolute;
      bottom: 30px;
      font-size: 0.85rem;
    }
`