import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../Components/Navbar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import categoryImage from '../Assets/categoryImage.png';
import allCategory from '../Assets/allCategory.png';
import allCategories from '../Assets/allCategories.png';
import Footer from "../Components/Footer";
import { useParams } from 'react-router-dom';
import allData from '../DummyData/wholeData.json'

import img1 from '../Assets/NavbarCoverImages/1.jpg';
import img2 from '../Assets/NavbarCoverImages/2.jpg';
import img3 from '../Assets/NavbarCoverImages/3.jpg';
import img4 from '../Assets/NavbarCoverImages/4.jpg';
import img5 from '../Assets/NavbarCoverImages/5.jpg';
import img6 from '../Assets/NavbarCoverImages/6.jpg';

const AllArticles = () => {
    const { CategoryId } = useParams();

    const temp = {
        "all" : img1, 
        "trending" : img2,
        "nature" : img3,
        "drawing" : img4,
        "comic" : img5,
        "review" : img6,
    }

    return (
        <Container>
            <Navbar CategoryId={CategoryId}/>
            <div className="main-content">
                <img className="banner" src={temp[CategoryId]} alt="" />

                {/* <div className="articles-container">
                    <div className="article1">
                        <div className="image">
                            <img src="https://static1.cbrimages.com/wordpress/wp-content/uploads/2023/03/split-image-of-black-and-white-court-of-owls-batman-and-v-for-vendetta-feature.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://www.elegantthemes.com/blog/wp-content/uploads/2019/07/ben-day-dots-featured-image.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://shorthand.com/the-craft/comics-journalism/assets/ZsSW1Fod6v/untitled-1-2560x1440.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://wallpapercave.com/wp/wp12167266.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article2"></div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://lh4.googleusercontent.com/proxy/ZF_SCuSERKTnm02smikKxQPaErqkCetl8t2TAX3UUjVMvwtuz6ShroKkLil9MauJz1LsxfnFDZScTr8VIWFMP3UrcRU55Bw2V0XKxL1_SvjAVf0v2mxDCbNFQM_KUHeGSeEBXA" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://bloximages.chicago2.vip.townnews.com/syvnews.com/content/tncms/assets/v3/editorial/3/e1/3e1ee983-9cf7-557a-a74a-6fb518d4a600/60945e72a2260.image.jpg?resize=400%2C339" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://static1.cbrimages.com/wordpress/wp-content/uploads/2023/03/split-image-of-black-and-white-court-of-owls-batman-and-v-for-vendetta-feature.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://www.elegantthemes.com/blog/wp-content/uploads/2019/07/ben-day-dots-featured-image.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://shorthand.com/the-craft/comics-journalism/assets/ZsSW1Fod6v/untitled-1-2560x1440.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://wallpapercave.com/wp/wp12167266.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://shorthand.com/the-craft/comics-journalism/assets/ZsSW1Fod6v/untitled-1-2560x1440.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://wallpapercave.com/wp/wp12167266.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article2"></div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://static1.cbrimages.com/wordpress/wp-content/uploads/2023/03/split-image-of-black-and-white-court-of-owls-batman-and-v-for-vendetta-feature.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://www.elegantthemes.com/blog/wp-content/uploads/2019/07/ben-day-dots-featured-image.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://shorthand.com/the-craft/comics-journalism/assets/ZsSW1Fod6v/untitled-1-2560x1440.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://wallpapercave.com/wp/wp12167266.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                </div> */}
            </div>

            <Footer />
        </Container>
    )
}

export default AllArticles

const Container = styled.div`
    .main-content{
        margin-top: -40px;
        padding: 50px 92px 50px 100px;
        
        .banner{
            width: 100%;
        }

        .articles-container{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            
            .article1{
                width: calc(25% - 3.33px);
                aspect-ratio: 1/1.1;
                /* background-color: #332b28; */
                margin-bottom: 3px;
                margin-right: 1.5px;
                margin-left: 1.5px;
                border: 1px solid transparent;


                .image{
                    width: 100%;
                    height: 190px;
                    display: grid;
                    place-items: center;
                    overflow: hidden;
                    
                    img{
                        width: 125%;
                    }
                }

                .category-name{
                    text-transform: uppercase;
                    margin-top: 10px;
                    padding: 0 5px;
                    font-size: 0.85rem;
                }

                .title{
                    font-weight: 600;
                    font-size: 1.25rem;
                    margin: 7.5px 0;
                    padding: 0 5px;
                }

                .author{
                    font-size: 0.75rem;
                    padding: 0 5px;
                    font-weight: 200;

                    b{
                        font-weight: 500;
                    }
                }
                
                &:hover{
                    cursor: pointer;
                    border: 1px solid black;
                    
                    .image{
                        img{
                            transition-duration: 500ms;
                            scale: 1.25;
                        }
                    }

                    .title{
                        text-decoration: underline;
                    }
                }
            }

            .article2{
                width: calc(50% - 3.33px);
                aspect-ratio: 1/0.57;
                background-color: #ebebeb;
                margin-bottom: 3px;
                margin-right: 1.5px;
                margin-left: 1.5px;
            }
        }
    }

    @media (max-width: 600px){
        .main-content{
            padding: 50px 20px 50px 20px;
        }
    }
`