import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../Components/Navbar'
import { useParams } from 'react-router-dom';

import pic211 from '../Assets/Position21/1.jpg'
import pic212 from '../Assets//Position21/2.jpg'
import pic213 from '../Assets//Position21/3.jpg'
import pic214 from '../Assets//Position21/4.jpg'
import pic215 from '../Assets//Position21/5.jpg'
import pic216 from '../Assets//Position21/6.jpg'

import pic221 from '../Assets//Position22/1.jpg'

import pic231 from '../Assets//Position23/1.jpg'

import pic241 from '../Assets//Position24/1.jpg'
import pic242 from '../Assets//Position24/2.jpg'
import pic243 from '../Assets//Position24/3.jpg'

import pic251 from '../Assets//Position25/1.jpg'
import pic252 from '../Assets//Position25/2.jpg'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const ShortSnap = () => {
    const { pageId } = useParams();

    return (
        <Container>
            {pageId == 0 ? (
                <>
                    <a href="/" className="back-btn">
                        <ArrowBackIosIcon />
                        Home Page
                    </a>
                    <h1>মানুষ কেন খুন করে</h1>
                    <p className="bigger-desc">এমন একটা বরফ কঠিন প্রশ্নের সহজ জলের মতো উত্তর আঁকলেন সুব্রত আচার্য</p>

                    <div className="images">
                        <img src={pic211} alt="" />
                        <img src={pic212} alt="" />
                        <img src={pic213} alt="" />
                        <img src={pic214} alt="" />
                        <img src={pic215} alt="" />
                        <img src={pic216} alt="" />
                    </div>
                </>
            ) : pageId == 1 ? (
                <>
                    <a href="/" className="back-btn">
                        <ArrowBackIosIcon />
                        Home Page
                    </a>
                    <h1>দেওয়াল খুন</h1>
                    <p className="bigger-desc">লিখছেন নবনীতা ভট্টাচার্য</p>

                    <div className="full-image">
                        <img src={pic221} alt="" />
                    </div>

                    <p>
                        শিয়রে ভোট এলে কিছু দেওয়াল খুন হয়ে যায়। <br />
                        দেওয়াল দেওয়াল দেওয়াল চাই-দেওয়ালে লিখে ভোট চাই। ভোট মানে দেওয়ালের হোলি
                        খেলা। প্রতিটা দলই এক একটা রং বুঝে নিয়ে কারবার করে। দেওয়ালে লেখা নাম আবার
                        কোনও মানুষের রাজনৈতিক বোধ সম্পর্কেও অবহিত করে। <br /> <br />
                        তখন বড়ো হচ্ছি, দেখছি কাস্তে হাতুড়ি আঁকা দেওয়ালে দেওয়াল। কী প্রেম কী প্রেম!
                        ওই দেওয়ালগুলোকে মনে হত বুঝি আমার পরিবারের রং, মনে হত আমরা অনেকেই
                        একরকম। পরিবারের বাইরের মানুষদের সাথেও এই রঙের কারণে খুব সহজেই
                        আত্মীয়তা, বন্ধুত্ব হয়ে যেত অনেক বেশি। কিন্ত বড়ো হয়ে বুঝেছি সব দেওয়াল লাল
                        হয়ে গেলে মুশকিল। গণতন্ত্রে ভিন্ন মত থাকা জরুরি। তখন অন্য দেওয়াল, প্রার্থীদের
                        নাম, ম্যানিফেস্টো খুঁজে দেখা জরুরি মনে হত। সে যাই হোক দেওয়ালের কথায়
                        ফিরি। <br /> <br />
                        ছোটোবেলার খবরে শুনতাম দেওয়াল ধরা নিয়ে হেবি ঝাড়পিট হয়েছে। সে যেরকম
                        দেওয়ালই হোক। ছোটো, বড়ো, নোংরা, ধলা। চোখের সামনে, পিঠের পিছনে, আশে-পাশে,
                        চোখের কোণে, বাম দিকে, ডান দিকে, হোঁচট খেয়ে, থমকে, চমকে, চোখের ওপর
                        দেওয়ালে দেওয়ালে রঙের খেলা। <br /> <br />
                        নির্বাচন এগিয়ে এলেই দেওয়ালগুলো কেমন আবোল-তাবোলের ঢঙে রঙিন হয়ে ওঠে।
                        লাল-নীল-সবুজ-কমলা। ওরাং-ওটাং সদৃশ। সব দেওয়ালে রঙের পোচ লেগেছে। পোচ বলতে
                        মনে পড়ল সাদা দেওয়ালে ঠিক ডিমের হলুদ কুসুমের মতো রং খেলছে, সূর্যের কমলা
                        খেলছে, রক্তের রং খেলছে, সবুজ বনান্তর খেলছে। <br /> <br />
                        এবার দেখলাম ক্রিয়েটিভ দেওয়াল। মানে চোরাগোপ্তা দেওয়াল লিখন। অন্ধকার রাস্তা
                        দিয়ে চুপচাপ হাঁটছেন, কোথাও কেউ নেই। এদিকে মোড় ঘুরতেই দেখলেন, ঝামড়ে ওঠা
                        বোগেনভেলিয়ার দেওয়ালে ফাঁকফোকর গলে একটি অচেনা নাম খেলা করছে। এর তলা
                        তার তলা হয়ে সে ব্যাটা বেলতলায় এসে ঘাপটি মেরেছে। কষ্ট পেলাম এই দেখে যে
                        কালুকাকার ভালো নামটা দেওয়াল থেকে মুছে দিয়েছে কারা। যার দেওয়াল তারই নাম নেই!
                        তাজ্জব কি বাত! <br /> <br />
                        দেওয়াল জুড়ে নতুন আর পুরোনো মিলিয়ে সে একেবারে রাজনৈতিক কচকচানি-- যেন
                        তরজা। আর আজকাল তা খেউড়ের জায়গায় পৌঁছে গেছে। যা নির্বাচনের পরে বোঝা
                        যায় হয় সেদ্ধ হয়নি, নয় বেসুরো বা গালাগালির সমান। খাস কলতলায় দুপুর রোদে

                        দাঁড়িয়ে তোর বালতি না মোর… খাঁটি ঝগড়া। দেওয়ালের রংগুলো যেন দুপুর রোদে একে
                        অপরকে দাঁত খিঁচোতে যায়।
                        নির্বাচনের নির্ঘণ্ট শেষে আবার সব হাওয়া। যেন ভূতের রাজার গল্প। তাও গুপি-
                        বাঘা জবর জবর তিন বর পেয়েছিল। আমাদের ভাঁড়ার শূন্য।  <br /> <br />
                        ভোট মিটে গেলে দেওয়ালের‌ আর কোনও রাজনৈতিক দাবিদার থাকে না। বেওয়ারিশ হয়ে
                        পড়ে থাকে। এরপর পাঁচ বছর ধরে দেওয়ালের রং চাটা থুড়ি চটবে দেখা ছাড়া জনগণের
                        আর কিছুই করার নেই। দেওয়ালে দেওয়ালে শুধু শ্যাওলা। কেন্নো কেঁচো জোঁক
                        পোকামাকড়ের উটকো সংসার। <br /> <br />
                        বড়োজোর রাস্তার ধারের আলো-অন্ধকারে কখনও দেওয়ালে হেলান দিয়ে দাঁড়ায় কোনও
                        ফেকু প্রেমিক-প্রেমিকা। নিঝুম দুপুরে পুচ করে গুঠকা থুকে দিয়ে যায় রোগা এক
                        সেলসম্যান। পাড়ার নেড়ি আর ন্যাড়া লালটু দেওয়াল ভিজিয়ে দিয়ে যায় নিয়ম করে। কেন
                        যে ওদের দেওয়াল দেখলেই ইচ্ছে করে! তবে ওইটুকু তো আছে। রাজনীতি যেমন দেওয়াল
                        জুড়ে এসেছিল, তেমন জনগণকে নোংরা দেওয়াল ধরিয়ে দিয়ে চলে গেছে। <br /> <br />
                        জনগণ তাই সেই দেওয়াল ধরে দাঁড়িয়ে থাকুন আর ভাবার চেষ্টা করুন যে দেওয়ালের
                        আদি রংটি কী ছিল!
                    </p>
                </>
            ) : pageId == 2 ? (
                <>
                    <a href="/" className="back-btn">
                        <ArrowBackIosIcon />
                        Home Page
                    </a>
                    <h1>কী যেন খুন হয়ে গেছে</h1>
                    <p className="bigger-desc">লিখছেন সঞ্জিত ভট্টাচার্য্য</p>

                    <div className="full-image">
                        <img src={pic231} alt="" />
                    </div>

                    <p>
                        কলকাতার সব চাইতে পুরোনো ট্রাম ডিপোগুলোর মধ্যে একটা টালিগঞ্জ। যার হাঁ-করা মুখের সামনে
                        বাঙালির চিরদিনের ম্যাটিনি আইডল মেট্রো স্টেশনের দিকে হাঁটতে গিয়ে, থমকে রয়েছেন। ডিপোর
                        লেজে আবার গ্রাহাম রোড আর টালিগঞ্জ ক্লাবের গল্ফ কোর্স ঘিরে থাকা উদ্ধত, দাম্ভিক সব
                        গাছের সারি।  <br/> <br/>
                        বছর চল্লিশ আগে ট্রাম ডিপোর এই ল্যাজ আর মুড়োর মাঝে ছিল, ডিপোর জাঙ্ক ইয়ার্ড। ভাঙাচোরা
                        ট্রামের কঙ্কাল, জং পড়ে যাওয়া ইয়া বড়ো বড়ো সব ট্রামের চাকা, শ্যাওলা পড়ে যাওয়া কিছু লাইফ
                        গার্ড ট্রে। এ সব কিছু ছড়িয়ে ছিটিয়ে পড়ে থাকত প্রায় তিন চার বিঘা অঞ্চল জুড়ে।  <br/> <br/>
                        আর এই অযত্নে পড়ে থাকা জিনিসপত্রগুলোকে আদর দিয়ে ঢেকে রাখত কয়েক হাজার পুটুস ফুলের
                        গাছ। একটা বোঁটার ওপর খুব ছোটো ছোটো হলুদ,সাদা, কমলা রঙের একথোকা ফুল। কোমর সমান
                        ঝুপসি ঝোপে ফুটে থাকত অগুনতি।  <br/> <br/>
                        ট্রাম বাইরে থেকে ভেতরে ঢুকত, ডিপোর লেজ বেয়ে এই জাঙ্ক ইয়ার্ডের পাশ দিয়ে। শীতের রাতে
                        পাতলা কুয়াশার চাদরে ঢাকা এই ইয়ার্ড ভরা পুটুস ফুলের জঙ্গলের কালো ছায়া ফুঁড়ে বেরিয়ে থাকা
                        ট্রামের ফসিলগুলোকে দেখে কেন জানি না, মনে হত কবরখানা। পেরিয়ে যেতে একটা ট্রামের সময়
                        লাগত, মিনিটখানেক। আর সেই এক মিনিটেই যেন আস্ত একটা ম্যাজিক! ট্রামের ভেতরের হলদে
                        আলো আর ওই জাঙ্ক ইয়ার্ডের নিকষ কালো অন্ধকার, পুটুস ফুলের আশ্চর্য বুনো গন্ধ মেখে, এক
                        আধিভৌতিক পরিবেশ তৈরি করত। ট্রামের ঘটঘটাং শব্দ ছাপিয়ে হাজার ফুলের ঝোপে ঝোপে লক্ষ
                        ঝিঁঝি পোকার ডাকে ভরে থাকত রাতের ট্রামের সেই খুচরো মুহূর্তগুলো।  <br/> <br/>
                        তবে সে পুটুসের মাঠ সকালের নরম রোদে ছিল আর এক রকম। বেজি আর ফিঙে পাখিদের হাঁকডাক আর
                        দৌড়াদৌড়িতে একেবারে সরগরম। হলুদ প্রজাপতি আর কমলা ফড়িঙের ঝাঁক হয়ে, ওই হলুদ কমলা
                        ফুলগুলোই যেন উড়ে বেড়াত গোটা জাঙ্ক ইয়ার্ড জুড়ে। তাদের সঙ্গে পাখা মেলাত চার পাঁচটা প্রাইমারি
                        স্কুল ফেরত সাদা হাফপ্যান্ট আর হলুদ জামার কুচো। ওই পুটুস ফুলের পাড়ার ভেতর দিয়ে ওদের স্কুল
                        থেকে বাড়ি ফেরার শর্টকাট। হাতে হাতে ছোট্ট অ্যালুমিনিয়ামের বাক্সে ব্রাউন পেপারের মলাটে
                        কয়েকটা বই খাতার সাথে ছোটো টিফিন বক্স । টিফিন বক্সে বেজিদের জন্য বাঁচিয়ে আনা কয়েকটা
                        আধ খাওয়া পাউরুটি আর এক পৃথিবী ভালোবাসা।  <br/> <br/>
                        তারপর যা হয়, সবার হয়-- সেই ট্রামলাইনের ওপর দিয়ে ছোটো ছোটো পায়ে ব্যালান্স করতে করতে,
                        এগিয়ে যাওয়া। আরও আরও আরও । যেখান থেকে ধীরে ধীরে ছোটো হতে হতে মিলিয়ে যায়, পাখি,
                        প্রজাপতি, বেজিদের দল। সাথে ওই চার বিঘার পুটুস ফুলের পাড়া। ফিকে হয়ে যায় লক্ষ ঝিঁঝি পোকার
                        পিছু ডাক।  <br/> <br/>
                        তখন ইচ্ছে হলেও কি ফেরা যায় সেই পুটুস ফুলের পাড়ায়! সে পাড়া ততদিনে বড়ো হয়ে গেছে। পুটুস
                        ফুলের পাড়া, চাপা পড়ে গেছে, বালি সিমেন্ট লোহালক্কড়ের নীচে। বেজির ডেরায়, পেটে চার্জার লাগিয়ে
                        দম নিচ্ছে ইলেকট্রিক ভলভো বাস।  <br/> <br/>
                        লাখ এলইডি আলোর বন্যায় কোথায় ভেসে গেছে ছেলেবেলার সেই ঝিম ধরানো অন্ধকার। ঘুঘু ডাকা
                        নির্জন দুপুর, আর সেই হলুদ প্রজাপতির দল। সুড়সুড়ি ঘাসের (কেন এই নাম জানা নেই) মাথা থেকে
                        বিন্দু বিন্দু খুশি কুড়িয়ে এনে এক নিমেষে ছেলেবেলা ভরিয়ে দিতে পারত। বোধহয় তারাও বড়ো হয়ে বেনে
                        বউ পাখি হয়ে গেছে।  <br/> <br/>

                        এক পাড়া পুটুস ফুলের কবরের ওপর খাড়া হয়ে আছে হিং ঢুকিয়ে মেরে ফেলা অনেক পুরোনো একটা
                        শিরীষ গাছ। যার গোড়ায় একটা ছোটো ডিম পাউরুটির দোকান। এই বাঁশ আর প্লাস্টিকের যৌথ প্রয়াসে
                        বেজির দল শুধু বাদ পড়ে গেছে।
                    </p>
                </>
            ) : pageId == 3 ? (
                <>
                    <a href="/" className="back-btn">
                        <ArrowBackIosIcon />
                        Home Page
                    </a>
                    <h1>আহ্লাদে জল্লাদ</h1>
                    <p className="bigger-desc">লিখছেন সর্বাণী আচার্য</p>

                    <div className="full-image">
                        <img src={pic241} alt="" />
                    </div>

                    <p>
                        খুন করার মধ্যে উল্লাস থাকতে পারে। সেই উল্লাসকে আবার আনন্দ বলে চালাতেও পারি। কিন্তু
                        শুধুমাত্র উল্লাস হবে এই ভেবে যখন খুন করা হয়! হয়। এমন আকছার হয়। ইতিহাসে বহু নজির আছে।
                        তবে শুধু মানুষকে দোষ দিয়ে লাভ নেই। এমন ঘটনা, প্রাণীজগতেও বিরল নয়। হিংস্র প্রাণীরা তো
                        বটেই, বাজারে নিরীহ বলে সুনাম আছে এমন বহু প্রাণীর মধ্যেও এই ধরনের আচরণ দেখা যায়। যেমন
                        ধরা যাক ডলফিন। মানুষ টিকিট কেটে এদের সার্কাস দেখতে যায়। হাসিমুখে গলা জড়িয়ে ছবি তোলে।
                        একসঙ্গে নিশ্চিন্তে সাঁতরায়। যে সাঁতার কাটছে কাটুক! আমি সেই ফাঁকে ১৯৯৭ সালের একটা ঘটনার
                        কথা বলি। আমেরিকার ভার্জিনিয়া উপকূলে ভেসে এসেছিল একাধিক শুশুকের মৃতদেহ। জীববিজ্ঞানী
                        সেই সব মৃতদেহ পরীক্ষা করে দেখেছিলেন, যে তাদের পাঁজর ভাঙা, লিভারে আর ফুসফুসে ক্ষত। গোটা
                        শরীর জুড়ে কালশিটে। আরও গভীরভাবে পরীক্ষা-নিরীক্ষা চালিয়ে বিজ্ঞানীরা শুশুকগুলোর শরীরে
                        কামড়ের চিহ্ন খুঁজে পান। দাঁতের সঙ্গে দাঁত মিলিয়ে দেখেন ডলফিন। সাধারণভাবে শুশুক কখনওই
                        ডলফিনকে হামলা করে না। ডলফিনেরও কোনও কারণ নেই শুশুককে শিকার বানানোর! বিজ্ঞানীরা
                        বুঝেছিলেন, শুশুকগুলোকে ধীরে ধীরে হত্যা করা হয়েছে। তাড়িয়ে তাড়িয়ে আনন্দ নিতে এই খুন। শুধু
                        শুশুক নয়, স্বজাতিও খুন হয়। স্রেফ মজার জন্য। মৃত ডলফিনের দেহ নিয়ে তারপর চলে স্বজাতি
                        ডলফিনদের ডলফিনিও মজা। <br/> <br/>
                        এবার সুদূর আমেরিকা থেকে চোখ সরিয়ে আপাতত আপনার পাপোশের দিকে তাকান। নরম তুলোর
                        মেনিবল ঘুমোচ্ছেন। ভাবছেন তাকে আবার কেন টানছি! খুন করে আনন্দ পাওয়াদের তালিকায় এই
                        মহারাজের নামটিও জ্বলজ্বল করছে। জীবন্ত প্রাণীকে ধাওয়া করে খুন করতেই এর বেশি আনন্দ।
                        ইঁদুরটাকে শুধু ধরা নয়—খেলতে খেলতে কুপোকাত করায় যে কী আনন্দ তা আপনার মেনিবলটি ভালোই
                        জানে। <br/> <br/>
                        আবার ধরুন শিম্পাঞ্জি। এর খাদ্য তালিকায় মাংস তেমন ভাবে থাকে না। কিন্তু নিজেদের মধ্যে যখন
                        লড়াই চলে, তখন হেরোকে মাটিতে ফেলে কামড়ে, মারধর করে হত্যা করাই রীতি। সম্ভবত পুরো কাজটি
                        দ্রুততর সারাই যায়। কিন্তু শত্রুর ধীর এবং যন্ত্রণাদায়ক মৃত্যুর জন্যই অপেক্ষা করে
                        শিম্পাঞ্জিরা। যে ভাবে শিম্পাঞ্জিরা নিজেদের শিকারের উপর নির্যাতন চালায় আর তার মাংস খেতে
                        থাকে, তাতে মনে হয়—গোটা প্রক্রিয়া থেকে আনন্দ পাওয়ার একটা বড় তাগিদ তাদের মধ্যে হয়তো
                        কাজ করছে। <br/> <br/>
                    </p>

                    <div className="full-image">
                        <img src={pic242} alt="" />
                    </div>

                    <p>
                        ১৯৯৪ সালে দক্ষিণ আফ্রিকায়, পিলানেসবারগ জাতীয় উদ্যানে এক অবাক কাণ্ড। উদ্যানের
                        জলাশয়ের কাছে একের পর এক মৃত গন্ডার। আধিকারিকেরা প্রাথমিক ভাবে ভেবেছিলেন,
                        চোরাশিকারিদের কারবার। শেষে ভুল ভাঙে। অল্পবয়সি কয়েকটি হাতির কাজ। উঠতি বয়সের কিশোর
                        হাতিগুলি কোনও বড়ো প্রাণীর ওপর শক্তি পরীক্ষা করতে চাইছিল। স্রেফ শক্তি যাচাই করতে গিয়ে
                        খুন! তাও একটা নয়। তিন মাসের মধ্যে দশ দশটা। আমাদের চারপাশে কত কী ঘটে চলেছে। আমরা কি
                        আর বুঝি! তার ওপর মনের কথা। তাও আবার মনুষ্যেতর-র মন।! সেই মনের আবার অন্ধকার ঢাকা
                        বিষয় আশয়!  <br/> <br/>
                        এখন আমার সামনে সানডে চারপা ছড়িয়ে ক্যাবলার মত ঘুমোচ্ছে। আহা কী সরল জীব-- মনে করলে
                        ডাহা ফেল করবেন। সরল ভাব নিমেষে উধাও হয়ে যাবে, যদি মঞ্চে একটি টিকটিকি এসে জোটে। ধরতে
                        পারুক আর নাই পারুক টিকটিকি বেচারার নাভিশ্বাস নিশ্চয়ই তুলে দেবে। আমরা মাঝে মাঝে ছাদে
                        টিকটিকির মৃতদেহ আবিষ্কার করি। সান্ধ্য ভ্রমণে গিয়ে টিকটিকি শিকারে কেমন আনন্দ তা নিয়ে
                        প্রশ্ন করলেই, একহাত জিভ বার করে উত্তর দেয়— বহুত! এক্কেবারে আহ্লাদে আহ্লাদে সাক্ষাৎ
                        জল্লাদ! আমার কথা বিশ্বাস না হলে এসে দেখে যাবেন একটিবার।
                    </p>

                    <div className="full-image">
                        <img src={pic243} alt="" />
                    </div>
                </>
            ) : (
                <>
                    <a href="/" className="back-btn">
                        <ArrowBackIosIcon />
                        Home Page
                    </a>
                    {/* <h1>অজ্ঞানের বিজ্ঞান/ ১</h1>
                    <p className="bigger-desc">লিখছেন গৌতম সাহা</p> */}

                    <div className="single-images">
                        <img src={pic251} alt="" />
                        <img src={pic252} alt="" />
                    </div>
                
                    {/* 
                    <p>
                        আন্তর্জাতিক কনফারেন্স। সর্বশ্রেষ্ঠ বিজ্ঞানকেন্দ্রে দুনিয়া-শ্রেষ্ঠ বৈজ্ঞানিক এবং
                        রাষ্ট্রপ্রধানেরা এসে জুটেছেন। নেতাদের জ্ঞানের ভাণ্ডারের খানিকটা বৈজ্ঞানিকদের মধ্যে
                        ট্রান্সফার করাই একমাত্র উদ্দেশ্য নয়! নতুন নতুন, তাক লাগানো উদ্ভাবন, নেতাদের দেখিয়ে বাহবা
                        পাওয়াও উদ্দেশ্য। দুতরফা একটা ব্যাপার।  <br/> <br/>
                        দ্বিতীয় দিনের শেষে তখন খানাপিনা চলছিল বেশ। চলছিল হাসিঠাট্টা। বৈজ্ঞানিকরা,
                        রাষ্ট্রপ্রধানদের সঙ্গে বেশি মাত্রায় মজা শুরু করে দিলেন। আহেতুক বিভিন্ন পদার্থ ভেঙে শুরু হল
                        ভেলকি। বস্তুর ছোটো কণা নিয়ে খেলা। ইলেকট্রন, প্রোটন যখন নাচতে শুরু করল রাষ্ট্রনেতারা
                        আরও মজা পেলেন। প্রধানেরা আরও ভেলকি, আরও নাচ দেখতে চাইছিলেন। তিন বৈজ্ঞানিক খুব
                        খাটলেন। খুব ভাঙলেন। শেষে এমন একটা পর্যায়ে পৌছালেন যে পদার্থের সূক্ষ্মকণা আর তাঁদের
                        অধীনে থাকল না। ছুটে বেরিয়ে গেল মহাশূন্যের দিকে। ঠিক সেই সময়ে শূন্যের মধ্যে আলোর কোটি
                        কোটি কণা নাচছিল। প্রায়ই নাচে। আলোর বিশাল ডান্স ফ্লোরে পৃথিবী থেকে ছিটকে যাওয়া কণা
                        বেমালুম ঢ়ুকে পড়ল। নক্ষত্র থেকে ঠিকরে পড়া আলোর সঙ্গে বস্তুর কণা মিশে গিয়ে একসঙ্গে
                        দমাদম নাচ। এই রকম নাচানাচির সময় অন্য গ্রহ বা নক্ষত্রের আলোর মধ্যে থাকা কিছু চালাক
                        চালাক আলো পৃথিবী থেকে আসা কণাগুলোর ধর্ম পরিবর্তন করার চেষ্টা শুরু করে দিল। <br/> <br/>
                        এই চালাক আলো অনেককাল ধরেই পৃথিবীর ভেতর ঢুকে মানুষের ধর্ম, স্বভাব, খোলনলচে বদলে
                        দেওয়ার চেষ্টা করে আসছে। বদলে দেওয়ার চেষ্টা করছে মানুষের মাথার ভেতরকার কোষ। যে সব
                        কোষের মধ্যে থাকা বুদ্ধি মানুষকে সভ্য থেকে আরও সভ্য করে তুলেছে, সেই সব কোষের ভোল বদলে
                        দেওয়ার ষড়যন্ত্র অনেক দিনের। এই আলোর চেষ্টা সফল হলে মানুষের উন্নতি স্তব্ধ হয়ে যেত।
                        মহাশূন্যের এই চালাক আলোর সমস্ত চেষ্টা ব্যর্থ হয়েছে শুধুমাত্র পৃথিবীর ওপর এক বিশেষ
                        বায়ুস্তরের জন্য। এই স্তরের বৈজ্ঞানিক নাম সেয়ানা স্তর। ওজন স্তরের দু-ধাপ ওপরে এই
                        শক্তিশালী বায়ুস্তরের অবস্থান। অনেক দূর থেকে চালাক আলো এসে সেয়ানা স্তরে ধাক্কা খেয়ে
                        ফিরে গেছে। বার বার। কিন্তু তিন বৈজ্ঞানিকের ভেলকি দেখানোর ফলে তৈরি হওয়া পদার্থের ছোটো
                        কণা সমস্ত কন্ট্রোল হারিয়ে যখন মহাশূন্যের ভেতর আলোর সঙ্গে নাচতে শুরু করল, ঠিক তখনই,
                        ওই চালাক আলো বস্তুর কণাদের মূল গঠন পরিবর্তন করে দিল। প্রচণ্ড গতিতে বস্তুর কণা
                        মহাশূন্যের নেত্তশালা ছেড়ে অনেক দূরের এক গ্রহের দিকে ছুট লাগাল। <br/> <br/>
                        আর  মুহূর্তের মধ্যে পৃথিবীর পুরো কন্ট্রোল চলে গেল দুহাজার নয় কোটি আলোকবর্ষ দূরের এক
                        গ্রহে। এক ইয়ার্কিতে পৃথিবীর সভ্যতা এবং জীবনযাত্রা কয়েক হাজার গুণ এগিয়ে গেল।
                    </p> */}
                </>
            )}
        </Container>
    )
}

export default ShortSnap

const Container = styled.div`
    width: 100vw;
    padding: 5vh 10vw;

    .back-btn{
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 0.75rem;
        height: 30px; 
        width: 120px; 
        border-radius: 100px;
        background-color: #d6d8df;

        cursor: pointer;
        color: #333;

        text-decoration: none;

        svg{
            font-size: 0.85rem;
        }
    }

    h1{
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 4rem;
        font-weight: 400;
    }
    
    .images{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: auto;

        img{
            width: calc(50% - 10px);
            /* border: 1px solid #bdbaba; */
            margin-bottom: 20px;
        }

        @media (max-width: 600px){
            img{
                width: 100%;
            }
        }
    }

    .full-image{
        width: 100%;

        img{
            width: 100%;
            /* border: 1px solid #bdbaba; */
            margin-bottom: 20px;
        }
    }

    .single-images{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        img{
            width: 100%;
            max-width: 700px;
        }
    }

    .bigger-desc{
        font-size: 1.5rem;
        font-weight: 300;
        margin-bottom: 40px;
    }

    p{
        font-size: 1.25rem;
        font-weight: 300;
        margin-bottom: 40px;
    }

    @media (max-width: 600px){
        h1{
            font-size: 2.75rem;
        }

        p{
            font-size: 0.85rem;
            font-weight: 300;
            line-height: 1.75rem;
            margin-bottom: 40px;
        }
    }
`
