import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../Components/Navbar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Footer from '../Components/Footer';
import bannerBengali from '../Assets/bannerBengali2.jpg'
import bee from '../Assets/bee.jpg'
import arrow from '../Assets/arrow.png'
import bullConvesation from '../Assets/bull.jpg'
import podcast from '../Assets/podcast.jpg'
import podcastVoice from '../Assets/podcast.mp3'
import coverImage from '../Assets/coverImage.jpg'
import pic21 from '../Assets/Position2/1.jpg'
import pic22 from '../Assets//Position2/2.jpg'
import pic23 from '../Assets//Position2/3.jpg'
import pic24 from '../Assets//Position2/4.jpg'
import pic25 from '../Assets//Position2/5.jpg'



const Landing = () => {
    const items = [pic21, pic22, pic23, pic24, pic25];
   
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsToShow = 3;

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNextClick = () => {
        if (currentIndex < items.length - itemsToShow) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const [currentIndex2, setCurrentIndex2] = useState(0);
    const itemsToShow2 = 1;

    const handlePrevClick2 = () => {
        if (currentIndex2 > 0) {
            setCurrentIndex2(currentIndex2 - 1);
        }
    };

    const handleNextClick2 = () => {
        if (currentIndex2 < items.length - itemsToShow2) {
            setCurrentIndex2(currentIndex2 + 1);
        }
    };

    return (
        <Container>
            <Navbar />
            {/* <div className="banner handdraw"> */}
            <div className="banner">
                <img src={bannerBengali} />
            </div>

            <div className="main-content">
                <div className="left-content">
                    <div className="top-content">
                        <img src={coverImage} alt="" />
                    </div>
                    <div className="bottom-content-big">
                        <div className="left-btn" onClick={handlePrevClick}><ChevronLeftIcon /></div>
                            {items.map((pic, index) => {
                                if (index >= currentIndex && index < currentIndex + itemsToShow) {
                                    return (
                                        <a href={`short-snap/${index}`} key={index} className="small-page">
                                            <img src={pic} alt={`pic${index}`} />
                                        </a>
                                    );
                                }
                                return null;
                            })}
                        <div className="right-btn" onClick={handleNextClick}><ChevronRightIcon /></div>
                    </div>
                    <div className="bottom-content-small">
                        <div className="left-btn" onClick={handlePrevClick2}><ChevronLeftIcon /></div>
                            {items.map((pic, index) => {
                                if (index >= currentIndex2 && index < currentIndex2 + itemsToShow2) {
                                    return (
                                        <a href={`short-snap/${index}`} key={index} className="small-page">
                                            <img src={pic} alt={`pic${index}`} />
                                        </a>
                                    );
                                }
                                return null;
                            })}
                        <div className="right-btn" onClick={handleNextClick2}><ChevronRightIcon /></div>
                    </div>
                </div>
                <a href="/bee-story" className="right-content">
                    <img src={bee} alt="" />
                </a>
            </div>

            <a href="/bee-story" className="only-phone">
                <img src={bee} alt="" />
            </a>

            <div className="media-video-audio">
                <iframe className="non-phone" width="560" height="315" src="https://www.youtube.com/embed/AIfs7iguc28" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <iframe className="only-phone" width="560" height="315" src="https://www.youtube.com/embed/AIfs7iguc28" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div className="audio-box">
                    <div className="img-box">
                        <img src={podcast} alt="" />
                    </div>
                    {/* <div className="podcast-list">Week 1</div> */}
                    <div className="podcast-name">এলোমেলো আড্ডার এক টুকরো</div>
                    <div className="podcast-name">জয়েন্দ্র চক্রবর্তী আর সঞ্জিত ভট্টাচার্য্য</div>
                    <audio controls>
                        <source src={podcastVoice} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
                <div className="podcast-text">
                    <img src={arrow} alt="" />
                    <p>Listen to our Latest Podcast on <b>Bhushondi!</b></p>
                </div>
            </div>

            <div className="bull-coversation">
                <a href="/bull-story">
                    <img src={bullConvesation} alt="" />
                </a>
            </div>

            <Footer />
        </Container>
    )
}

export default Landing

const Container = styled.div`
    .banner{
        /* padding: 0 100px; */
        width: calc(100vw - 200px);
        margin: 20px auto;
        height: 550px;
        overflow: hidden;

        display: flex;
        align-items: flex-end;
        /* border: 5px solid black; */

        
        img{
            width: 100%;
        }
    }

    .only-phone{
        display: none;
    }

    .handdraw {
        /*  can be shortened with border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;*/

        border-top-left-radius: 255px 15px;
        border-top-right-radius: 15px 255px;
        border-bottom-right-radius: 225px 15px;
        border-bottom-left-radius:15px 255px;

    }

    .main-content{
        width: 100vw;
        padding: 20px 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-content{
            width: calc(65% - 7.5px);

            .top-content{   
                height: 360px;
                width: 100%;
                /* background-color: #f7f7f7; */
                border: 1px solid #d8d4d4;
                margin-bottom: 15px;

                display: flex; 
                align-items: center;
                justify-content: center;

                img{
                    width: 100%;
                }
            }
            
            .bottom-content-big{
                position: relative;
                height: 300px;
                width: 100%;

                display: flex;
                align-items: center;
                justify-content: space-between;

                .small-page{
                    height: 300px;
                    width: calc(33.33% - 10px);
                    /* background-color: #f7f7f7; */
                    border: 1px solid #d8d4d4;
                    display: flex;  
                    align-items: center;
                    justify-content: center;

                    img{
                        height: 100%;
                        width: 100%;
                    }


                }

                .left-btn{
                    position: absolute;
                    height: 35px;
                    background-color: whitesmoke;
                    border: 1px solid black;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    
                    left: -10px;
                    top: 137.5px;

                    display: grid;
                    place-items: center;

                    cursor: pointer;
                }

                .right-btn{
                    position: absolute;
                    height: 35px;
                    background-color: whitesmoke;
                    border: 1px solid black;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    
                    right: -10px;
                    top: 137.5px;

                    display: grid;
                    place-items: center;

                    cursor: pointer;
                }
            }

            .bottom-content-small{
                display: none;
            }
        }
        
        .right-content{
            height: 675px;
            width: calc(35% - 7.5px);
            border: 1px solid #d8d4d4;
            padding: 10px;

            img{
                height: 100%;
                width: 100%;
            }
        }
    }

    .media-video-audio{
        /* background-color: #f7f7f7; */
        /* border: 1px solid #d8d4d4; */
        width: calc(100% - 192px);
        height: 315px;
        margin-left: 100px;
        margin-bottom: 20px;

        display: flex;
/* 
        iframe{
            margin-bottom: -8px;
        } */

        .audio-box{
            height: 100%;
            aspect-ratio: 1/1;
            background-color: #ff5959;
            margin-left: 20px;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px;

            .img-box{
                width: 100%;
                display: flex;
                justify-content: center;
                background-color: white;
                margin-bottom: 20px;

                img{
                    max-height: 140px;
                }
            }

            .podcast-list{
                padding: 5px 10px;
                font-size: 0.75rem;
                background-color: #f1f3f4;
                margin-top: 10px;
            }

            .podcast-name{
                font-size: 0.85rem;
                margin-top: 5px;
                color: white;
            }

            audio{
                width: 100%;
                margin-top: 20px;
            }
        }

        .podcast-text{
            flex: 1;
            height: 100%;
            /* background-color: orange; */
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img{
                width: 100%;
            }
            
            p{
                font-size: 2.25rem;
                text-align: right;
                font-weight: 500;

                b{
                    font-size: 4rem;
                    display: block;
                    line-height: 4rem;
                    font-weight: 600;
                    color: #ff5959;
                    /* font-style: italic; */
                }
            }
        }
    }

    .bull-coversation{
        background-color: #f7f7f7;
        border: 1px solid #d8d4d4;
        width: calc(100% - 192px);
        margin-left: 100px;

        img{
            width: 100%;
            margin-bottom: -10px;
        }
    }

    @media (max-width: 600px){
        .banner{
            /* padding: 0 100px; */
            width: calc(100vw - 40px);
            margin: 20px auto;
            height: auto;
            overflow: hidden;

            display: flex;
            align-items: flex-end;
            /* border: 5px solid black; */

            
            img{
                width: 100%;
            }
        }

        .only-phone{
            display: block;
            width: calc(100% - 40px);
            border: 1px solid #d8d4d4;
            padding: 10px;
            margin: auto;

            img{
                height: 100%;
                width: 100%;
            }
        }

        .handdraw {
            /*  can be shortened with border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;*/

            border-top-left-radius: 255px 15px;
            border-top-right-radius: 15px 255px;
            border-bottom-right-radius: 225px 15px;
            border-bottom-left-radius:15px 255px;

        }

        .main-content{
            width: 100vw;
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-content{
                width: 100%;

                .top-content{   
                    height: 360px;
                    width: 100%;
                    /* background-color: #f7f7f7; */
                    border: 1px solid #d8d4d4;
                    margin-bottom: 15px;

                    display: flex; 
                    align-items: center;
                    justify-content: center;

                    img{
                        width: 100%;
                    }
                }
                
                .bottom-content-small{
                    position: relative;
                    height: auto;
                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 20px 0;

                    .small-page{
                        height: 400px;
                        width: 100%;
                        /* background-color: #f7f7f7; */
                        border: 1px solid #d8d4d4;
                        display: flex;  
                        align-items: center;
                        justify-content: center;

                        img{
                            height: 100%;
                            width: 100%;
                        }


                    }

                    .left-btn{
                        position: absolute;
                        height: 35px;
                        background-color: whitesmoke;
                        border: 1px solid black;
                        aspect-ratio: 1/1;
                        border-radius: 50%;
                        
                        left: -10px;
                        top: 200px;

                        display: grid;
                        place-items: center;

                        cursor: pointer;
                    }

                    .right-btn{
                        position: absolute;
                        height: 35px;
                        background-color: whitesmoke;
                        border: 1px solid black;
                        aspect-ratio: 1/1;
                        border-radius: 50%;
                        
                        right: -10px;
                        top: 200px;

                        display: grid;
                        place-items: center;

                        cursor: pointer;
                    }
                }

                .bottom-content-big{
                    display: none;
                }
            }
            
            .right-content{
                display: none;
            }
        }

        .media-video-audio{
            /* background-color: #f7f7f7; */
            /* border: 1px solid #d8d4d4; */
            width: calc(100% - 0px);
            height: auto;
            margin-left: 0px;
            margin-bottom: 20px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            
            .non-phone{
                display: none;
            } 

            .audio-box{
                width: calc(100vw - 40px);
                background-color: #ff5959;

                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 20px;
                margin-top: 20px;

                .img-box{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    background-color: white;

                    img{
                        max-height: 140px;
                    }
                }

                .podcast-list{
                    padding: 5px 10px;
                    font-size: 0.75rem;
                    background-color: #f1f3f4;
                    margin-top: 10px;
                }

                .podcast-name{
                    font-size: 0.85rem;
                    margin-top: 5px;
                    color: white;
                }

                audio{
                    width: 100%;
                    margin-top: 20px;
                }
            }

            .podcast-text{
                display: none;
            }
        }

        .bull-coversation{
            background-color: #f7f7f7;
            border: 1px solid #d8d4d4;
            width: calc(100% - 40px);
            margin-left: 0px;
            margin: auto;

            img{
                width: 100%;
                margin-bottom: -10px;
            }
        }
    }
`