import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';
import { useParams } from 'react-router-dom';

const Contributor = () => {
    const { contributorId } = useParams();

    const [modifiedContributorId, setModifiedContributorId] = useState('');

    useEffect(() => {
        if (contributorId) {
            const formattedId = contributorId.replace(/-/g, ' ');

            setModifiedContributorId(formattedId);

            console.log(formattedId);
        }
    }, [contributorId]);

    return (
        <Container>
            <Navbar CategoryId="contributors" />
            <div className="main-content">
                <h2>All articles contributed by <b>{modifiedContributorId}</b></h2>
                <div className="articles-container">
                    <div className="article1">
                        <div className="image">
                            <img src="https://static1.cbrimages.com/wordpress/wp-content/uploads/2023/03/split-image-of-black-and-white-court-of-owls-batman-and-v-for-vendetta-feature.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://www.elegantthemes.com/blog/wp-content/uploads/2019/07/ben-day-dots-featured-image.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                    <div className="article1">
                        <div className="image">
                            <img src="https://shorthand.com/the-craft/comics-journalism/assets/ZsSW1Fod6v/untitled-1-2560x1440.jpg" alt="" />
                        </div>
                        <div className="category-name">Comic</div>
                        <div className="title">The satirical cartoon, supreme leader of freedom</div>
                        <div className="author">Contributed by, <b>Atanu Nayak</b></div>
                    </div>
                </div>
            </div>
            <Footer />
        </Container>
    )
}

export default Contributor

const Container = styled.div`
    .main-content{
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        padding: 50px 100px;

        h2{
            font-weight: 300;

            b{
                font-weight: 500;
                color: #a98a47;
            }
        }

        .articles-container{
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            
            .article1{
                width: calc(25% - 3.33px);
                aspect-ratio: 1/1.1;
                /* background-color: #332b28; */
                margin-bottom: 3px;
                margin-right: 1.5px;
                margin-left: 1.5px;
                border: 1px solid transparent;


                .image{
                    width: 100%;
                    height: 190px;
                    display: grid;
                    place-items: center;
                    overflow: hidden;
                    
                    img{
                        width: 125%;
                    }
                }

                .category-name{
                    text-transform: uppercase;
                    margin-top: 10px;
                    padding: 0 5px;
                    font-size: 0.85rem;
                }

                .title{
                    font-weight: 600;
                    font-size: 1.25rem;
                    margin: 7.5px 0;
                    padding: 0 5px;
                }

                .author{
                    font-size: 0.75rem;
                    padding: 0 5px;
                    font-weight: 200;

                    b{
                        font-weight: 500;
                    }
                }
                
                &:hover{
                    cursor: pointer;
                    border: 1px solid black;
                    
                    .image{
                        img{
                            transition-duration: 500ms;
                            scale: 1.25;
                        }
                    }

                    .title{
                        text-decoration: underline;
                    }
                }
            }

            .article2{
                width: calc(50% - 3.33px);
                aspect-ratio: 1/0.57;
                background-color: #ebebeb;
                margin-bottom: 3px;
                margin-right: 1.5px;
                margin-left: 1.5px;
            }
        }
    }
`