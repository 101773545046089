import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer';

const About = () => {
    return (
        <Container>
            <Navbar />
            <div className="main-content">
                <h2>About Bhushondi.com</h2>
                <div className="basic-text">
                    Greetings travelers! Welcome to Bhushondi.com, a platform dedicated primarily to visuals (but also to
                    words) such as cartoon, caricature, comic, collage etc. If you have a few words to share with the world
                    we are all ears (and eyes). It is an open arena for those who are interested to share their ideas in a novel
                    fashion.
                </div>
                <div className="basic-text">
                    <h3>Our Mission</h3>
                    Our mission simply provides quality content that provokes and informs our readers in a positive way.
                    We strive to create a space where diverse thoughts can share their unique perspectives.
                </div>
                <div className="basic-text">
                    <h3>Our Values</h3>
                    Integrity, inclusivity and inspiration are the core values that guide everything we do at Bhushondi.com.
                    We are committed to maintain the highest ethical standards in all our operations and to create an
                    environment where all perspectives are valued and respected.
                </div>
                <div className="basic-text">
                    <h3>Join our journey</h3>
                    Whether you are you looking to gain new insights, share your own, or simply explore new ideas,
                    Bhushondi.com is the place for you.
                    <ul>
                        <li><strong>Subrata Acharya</strong> - Founder, writer, illustrator and a tired editor</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </Container>
    )
}

export default About

const Container = styled.div`
    .main-content{
        min-height: calc(100vh - 200px);
        width: 100%;

        padding: 50px 100px;

        h2{
            font-size: 1.25rem;
            font-weight: 500;
        }

        .basic-text{
            margin-top: 10px;
            font-size: 0.85rem;
            font-weight: 200;
            line-height: 1.85rem;

            h3{
                font-weight: 500;
                font-size: 0.85rem;
                margin-top: 20px;
            }

            b, strong, a{
                font-weight: 500;
            }

            ul{
                margin-left: 40px;
            }
        }
    }
`