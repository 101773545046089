import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';
import submit from '../Assets/submit.jpg'

// Firebase
import { db } from "../firebase";
import { collection, getDocs, addDoc, updateDoc, doc, query, where } from "firebase/firestore";

const Contribute = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await addDoc(collection(db, "contributions"), {
                name: name,
                email: email,
                content: content,
                timestamp: new Date(),
            });
            alert('Form submitted successfully!');
            setName('');
            setEmail('');
            setContent('');
        } catch (error) {
            console.error("Error adding document: ", error);
            alert('Error submitting form, please try again.');
        }

        setLoading(false);
    };

    return (
        <Container>
            <Navbar />

            <div className="main-content">
                <div className="left box">
                    <h3>If you want to share your thoughts…</h3>
                    {/* <p>Please fill the below form, our team will mail you for further process within 48 hours</p> */}
                    <form className="form" onSubmit={handleSubmit}>
                        <input type="text" placeholder="Enter your Name" value={name} onChange={(e) => setName(e.target.value)}/>
                        <input type="text" placeholder="Enter your Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <textarea type="text" rows="20" placeholder="Topic of submission (visuals and/or write-ups)" value={content} onChange={(e) => setContent(e.target.value)}/>
                        {
                            loading ? <button>Sending...</button> : <button className="btn" type="submit">Submit</button>
                        }
                    </form>
                </div>
                <div className="right box">
                    <img src={submit} alt="" />
                </div>
            </div>

            <Footer />
        </Container>
    )
}

export default Contribute

const Container = styled.div`
    .main-content{
        height: 700px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 20px 100px;

        .box{
            width: calc(50% - 10px);
            height: 680px;
            overflow: hidden;
        }

        .left{
            padding-right: 40px;

            h3{
                font-size: 1.35rem;
                font-weight: 600;

                b{
                    color: #e9a001;
                }
            }

            p{
                font-size: 0.85rem;
                font-weight: 300;
            }

            .form{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                /* max-width: 400px; */

                margin-top: 20px;

                input, textarea {
                    padding: 7.5px 15px;
                    margin-bottom: 10px;
                    width: 100%;
                    background-color: #f9f9f9;
                    border: 1px solid #e9e1e1;
                    font-size: 0.85rem;
                    outline: none;
                }

                button{
                    padding: 7.5px 22.5px;
                    border: none;
                    background-color: #000;
                    color: white;
                    cursor: pointer;
                    margin-top: 10px;
                    border-radius: 100px;
                    z-index: 10;
                }
            }
        }

        .right{
            img{
                width: 100%;
            }
        }
    }
`