import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer';

const OrgContribute = () => {
    return (
        <Container>
            <Navbar />
            <div className="main-content">
                <h2>Contribute to Bhushondi.com</h2>
                <div className="basic-text">
                    At Bhushondi.com, we value diverse perspectives and are always eager to hear from voices around the world. If you have unique insights, experiences, or advice you'd like to share, we invite you to contribute an article to our platform.
                </div>
                <div className="basic-text">
                    <h3>Why Contribute?</h3>
                    Contributing to our platform allows you to reach a broader audience, enhance your professional profile, and be part of a community that values informative and thought-provoking content. Whether you're an experienced writer or a first-timer with something important to say, we want to hear from you.
                </div>
                <div className="basic-text">
                    <h3>Submission Guidelines</h3>
                    To ensure the quality of content and maintain the integrity of our platform, we ask that all submissions adhere to the following guidelines:
                    <ul>
                        <li>Original content: All articles must be original and not published elsewhere.</li>
                        <li>Relevance: Content should be relevant to our audience and can cover topics like technology, culture, environment, or personal development.</li>
                        <li>Length: Articles should be between 500 to 1500 words.</li>
                        <li>Formatting: Submissions should be well-organized and proofread for grammar and spelling errors.</li>
                        <li>Non-promotional: Articles should provide value to our readers and not be promotional in nature.</li>
                    </ul>
                </div>
                <div className="basic-text">
                    <h3>How to Submit Your Article</h3>
                    To submit your article, please send it in a Word document or Google Docs format to <a href="mailto:submit@bhushondi.com">submit@bhushondi.com</a>. Include a brief bio (50-100 words) and a link to your social media profiles or personal blog, if applicable.
                </div>
                <div className="basic-text">
                    <h3>Review Process</h3>
                    All submissions are subject to review by our editorial team. We aim to review submissions within 2 weeks and will contact you if your article is selected for publication. Due to the volume of submissions, we are only able to respond to those that are being considered for publication.
                </div>
                <div className="basic-text">
                    We look forward to your contributions and thank you for your interest in sharing your knowledge and experiences with the Bhushondi community!
                </div>
            </div>
            <Footer />
        </Container>
    )
}

export default OrgContribute

const Container = styled.div`
    .main-content{
        min-height: calc(100vh - 200px);
        width: 100%;

        padding: 50px 100px;

        h2{
            font-size: 1.25rem;
            font-weight: 500;
        }

        .basic-text{
            margin-top: 10px;
            font-size: 0.85rem;
            font-weight: 200;
            line-height: 1.85rem;

            h3{
                font-weight: 500;
                font-size: 0.85rem;
                margin-top: 20px;
            }

            b, a{
                font-weight: 500;
            }

            ul{
                margin-left: 40px;
            }
        }
    }
`