import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';

const Contributors = () => {
  return (
    <Container>
        <Navbar CategoryId="contributors"/>
        <div className="main-content">
            <p>All Contributor details will be added here!</p>
            {/* <a href="/contributor/@Atanu-Nayak" className="card">
                <div className="left"><img src="https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2024/141.png" alt="" /></div>
                <div className="right">
                    <div className="name">Andre Russell</div>
                    <div className="info">Contributed 28 Articles</div>
                </div>
            </a>
            <a href="/contributor/@Atanu-Nayak" className="card">
                <div className="left"><img src="https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2024/6.png" alt="" /></div>
                <div className="right">
                    <div className="name">Rohit Sharma</div>
                    <div className="info">Contributed 28 Articles</div>
                </div>
            </a>
            <a href="/contributor/@Atanu-Nayak" className="card">
                <div className="left"><img src="https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2024/2.png" alt="" /></div>
                <div className="right">
                    <div className="name">Virat Kohli</div>
                    <div className="info">Contributed 28 Articles</div>
                </div>
            </a>
            <a href="/contributor/@Atanu-Nayak" className="card">
                <div className="left"><img src="https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2024/16.png" alt="" /></div>
                <div className="right">
                    <div className="name">Manish Pandy</div>
                    <div className="info">Contributed 28 Articles</div>
                </div>
            </a>
            <a href="/contributor/@Atanu-Nayak" className="card">
                <div className="left"><img src="https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2024/2.png" alt="" /></div>
                <div className="right">
                    <div className="name">Virat Kohli</div>
                    <div className="info">Contributed 28 Articles</div>
                </div>
            </a>
            <a href="/contributor/@Atanu-Nayak" className="card">
                <div className="left"><img src="https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2024/16.png" alt="" /></div>
                <div className="right">
                    <div className="name">Manish Pandy</div>
                    <div className="info">Contributed 28 Articles</div>
                </div>
            </a>
            <a href="/contributor/@Atanu-Nayak" className="card">
                <div className="left"><img src="https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2024/2.png" alt="" /></div>
                <div className="right">
                    <div className="name">Virat Kohli</div>
                    <div className="info">Contributed 28 Articles</div>
                </div>
            </a>
            <a href="/contributor/@Atanu-Nayak" className="card">
                <div className="left"><img src="https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2024/16.png" alt="" /></div>
                <div className="right">
                    <div className="name">Manish Pandy</div>
                    <div className="info">Contributed 28 Articles</div>
                </div>
            </a> */}
        </div>
        <Footer />
    </Container>
  )
}

export default Contributors

const Container = styled.div`
    .main-content{
        min-height: calc(100vh - 200px);
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        padding: 50px 100px;

        .card{
            width: calc(33% - 10px);
            margin-right: 10px;
            margin-bottom: 10px;
            background-color: #f1f1f1;
            display: flex;
            align-items: center;
            padding: 20px;
            border: 1px solid transparent;
            /* border: 1px solid black; */
            text-decoration: none;

            .left{
                img{
                    height: 120px;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    background-color: #dddad4;
                }
            }

            .right{
                margin-left: 10px;
                .name{
                    font-size: 1.5rem;
                    font-weight: 500;
                }

                .info{
                    font-size: 0.85rem;
                    font-weight: 200;
                }
            }

            &:hover{
                border: 1px solid #d4b6b6;
                transition-duration: 250ms;

                img{
                    background-color: #e1cda4;
                }
            }
        }
    }
`