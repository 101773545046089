import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer';

const Contact = () => {
    return (
        <Container>
            <Navbar />
            <div className="main-content">
                <h2>Contact Us</h2>
                <div className="basic-text">
                    Thank you for your interest in Bhushondi.com. If you have any question or feedback please don’t
                    hesitate to reach out.
                </div>
                <div className="basic-text">
                    <h3>How You Can Reach Us</h3>
                    <ul>
                        <li>Our E-mail: <a target="_blank" href="mailto:bhushondi.core@gmail.com">bhushondi.core@gmail.com</a></li>
                        <li>Youtube page: <a target="_blank" href="https://www.youtube.com/@Bhushondi">@Bhushondi</a></li>
                    </ul>
                </div>
            </div>
            <Footer />
        </Container>
    )
}

export default Contact

const Container = styled.div`
    .main-content{
        min-height: calc(100vh - 200px);
        width: 100%;

        padding: 50px 100px;

        h2{
            font-size: 1.25rem;
            font-weight: 500;
        }

        .basic-text{
            margin-top: 10px;
            font-size: 0.85rem;
            font-weight: 200;
            line-height: 1.85rem;

            h3{
                font-weight: 500;
                font-size: 0.85rem;
                margin-top: 20px;
            }

            b, strong, a{
                font-weight: 500;
            }

            ul{
                margin-left: 40px;
            }
        }
    }
`