import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Navbar from '../Components/Navbar'

import bullInside from '../Assets/bullInside.png'
import bullRight from '../Assets/bullRight.jpg'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const BullConversion = () => {
    return (
        <Container>
            <a href="/" className="back-btn">
                <ArrowBackIosIcon />
                Home Page
            </a>
            <div className="one">
                সেই কোন প্রাচীনকাল থেকে পৃথিবীর পথেঘাটে নভোচারী
                কাক আর ভবঘুরে ষাঁড়ের অপূর্ব সখ্যতা।
            </div>
            <img src={bullInside} alt="" />
            <div className="two">
                <h1>বোঝার সংকট</h1>
                <p>সুব্রত আচার্য রচিত</p>
            </div>
            <div className="three">
                কাকের কাছে কানদুটো বিক্রি করে দিয়ে, ষাঁড় সুখে আছে। বোঝা কমে গেলে যা হয় আর কি!  <br/> <br/>
                কাক: রয়েছ দেখি বড্ড সুখে! <br/>
                নাকি পেটের অসুখে? <br/> <br/>
                ষাঁড়: আহা, কেমন মানিয়েছে দুই লোমশ কানে! <br/>
                শুনতে চাও তো বলি গানে গানে।  <br/> <br/>
                কাক: ফেলে রাখো ওই হেঁড়ে গলার গান— <br/>
                বুঝছ না তো শকুন খুঁটছে তোমার আমার জান! <br/>
                জান খুঁটছে, মান খুঁটছে, চাটছে মাথার খুলি— <br/>
                তবুও তোমায় গাইতে হবে বেসুর পদাবলি!  <br/> <br/>
                ষাঁড়: কয়লা কালো, তুমিও কালো, কালো রাতের গলি। <br/>
                চাঁদ উঠলে মনটি নাচে, ঝিংকু কথাকলি!  <br/> <br/>
                কাক: বুঝেছি তোমার হয়েছে শেষে ন্যাকা ভীমরতি, <br/> 
                দিলুভাই দিলদার বেহায়া প্রজাতি। <br/> <br/>
                ষাঁড়: ফুল ফুটলে তোমার খোঁজে দৌড়ে যাই বনে, <br/>
                কাঠবিড়ালি সুযোগ পেয়ে কামড় দেয় ওখানে। <br/>

                মনের দুখে, ওনার দুখে পাগল হয়ে ফিরি, <br/>
                ইচ্ছে করে ফুঁকেই ফেলি বানিয়ে তোমায় বিড়ি। <br/> <br/>
                কাক: ওরে বাবা ভাজা ভাজা হবে যত হাড়, <br/>
                গলায় যদি লটকে পড়ে বদ্ধ কালা ষাঁড়।
            </div>
            <img className="right-img" src={bullRight} alt="" />
        </Container>
    )
}

export default BullConversion

const Container = styled.div`
    width: 100vw;
    padding: 5vh 10vw;

    

    .back-btn{
        display: flex;
        align-items: center;
        justify-content: center;

        text-decoration: none;

        font-size: 0.75rem;
        height: 30px; 
        width: 120px; 
        border-radius: 100px;
        background-color: #d6d8df;

        cursor: pointer;
        color: #333;

        margin-bottom: 20px;

        svg{
            font-size: 0.85rem;
        }
    }

    img{
        margin-top: 20px;
        width: 100%;
        border: 1px solid #bdbaba;
    }

    .one{
        font-size: 1.5rem;
    }

    .two{
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;

        h1{
            font-size: 3.5rem;
        }

        p{
            font-size: 1.75rem;
        }
    }

    .three{
        font-size: 1rem;

        line-height: 2.5rem;
    }

    .right-img{
        border: none;
        position: absolute;
        right: 140px;
        bottom: 50px;
        width: 400px;
        
        @media (max-width: 600px){
            display: none;
        }
    }

    @media (max-width: 600px){
        .two{
            h1{
                font-size: 2.25rem;
            }

            p{
                font-size: 1rem;
            }
        }

        .three{
            font-size: 0.85rem;
            font-weight: 300;
            line-height: 1.75rem;
            margin-bottom: 40px;
        }
    }
`